export const config = {
	path: {
		news: "/news",
	},
}

export const links =  {
	console: 'https://console.scanv.com/#/', // 控制台
	cas: 'https://sso.yunaq.com/', // 创宇通行证
	logout: 'https://console.scanv.com/api/auth/logout', //退出登录
	login: 'https://sso.yunaq.com/cas/login?service=https%3A%2F%2Fconsole.scanv.com%2Fapi%2Fauth%2Flogin%2Fcas', // 登录
	register: 'https://sso.yunaq.com/accounts/register/?service=https%3A%2F%2Fconsole.scanv.com%2Fapi%2Fauth%2Flogin%2Fcas', // 注册
}
// export const links =  {
// 	console: 'http://127.0.0.1:8000/#/', // 控制台
// 	cas: 'http://test-cas.intra.jiasule.com/', // 创宇通行证
// 	logout: '/', //退出登录
// 	login: 'http://test-cas.intra.jiasule.com/cas/login?service=https%3A%2F%2Fconsole-dev1.scanv.com%2Fapi%2Fauth%2Flogin%2Fcas', // 登录
// 	register: 'http://test-cas.intra.jiasule.com/accounts/register/?service=https%3A%2F%2Fconsole-dev1.scanv.com%2Fapi%2Fauth%2Flogin%2Fcas', // 注册
// }
export const favicon = 'https://www.scanv.com/static/favicon.png';

export const header = 'https://www.yunaq.com/common-module/dist/header.js';

export const logo = 'https://www.scanv.com/static/logo.svg';

export const qrCode =
	"https://www.scanv.com/static/wechat-qrcode.png"
